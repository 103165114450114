// Import Core React Modules
import React from 'react';
import { SiGithub } from 'react-icons/si';
import { FaTwitter } from "react-icons/fa";
import { FaBurger } from "react-icons/fa6";
import { ImCross } from "react-icons/im";
import { PiGhostBold, PiDesktopTowerBold, PiMegaphoneBold } from "react-icons/pi";
import { FaHouseUser } from "react-icons/fa";
import { HiSquaresPlus } from "react-icons/hi2";

const Header = () => {
  return (
    <nav className="block-side-margin">
      <div id="navbar">
        <div className="logo">
          <a className="logo-text" href="/">&lt;mix<span className="logo-highlight highlight-light">e</span>l&gt;</a>
          <button class="hamburger-icon">
            <label for="dropdown">
              <FaBurger className="burger-icon" />
              <ImCross className="bones-icon" />
            </label>
            <input type="checkbox" id="dropdown" />
          </button>
        </div>

        <ul className="nav-links">
          <li className="nav-item-header toggle-header">
            <span>
              <HiSquaresPlus className="nav-icon flip-vertical toggle-icon" />Menu
            </span>
          </li>
          <li className="nav-item-text toggle-link">
            <a href="/">
              <FaHouseUser className="nav-icon toggle-icon" />Home
            </a>
          </li>
          <li className="nav-item-text">
            <a href="about">
              <PiGhostBold className="nav-icon toggle-icon" />About
            </a>
          </li>
          <li className="nav-item-text">
            <a href="projects">
              <PiDesktopTowerBold className="nav-icon toggle-icon" />Projects
            </a>
          </li>

          {/* Desktop Social Navigation Buttons */}
          <li className="nav-item-text">
            <a href="contact">
              <PiMegaphoneBold className="nav-icon flip-horizontal toggle-icon" />Contact
            </a>
          </li>
          <li className="nav-item-btn">
            <a 
              className="custom-btn btn-light mobile-btn"
              href="https://github.com/mjegan19"
              target="_blank"
              rel="noopener noreferrer"
            >Github&nbsp;&nbsp;<SiGithub /></a>
          </li>
          <li className="nav-item-btn">
            <a
              className="custom-btn btn-dark"
              href="https://twitter.com/mixelweb"
              target="_blank"
              rel="noopener noreferrer"
            >Twitter&nbsp;&nbsp;<FaTwitter /></a>
          </li>

          {/* Mobile Social Navigation Buttons */}
          <li className="nav-item-text toggle-link mobile-socials">
            <a 
              href="https://github.com/mjegan19"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SiGithub className="nav-icon toggle-icon" />Github
            </a>
          </li>
          <li className="nav-item-text toggle-link mobile-socials">
            <a 
              href="https://twitter.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitter className="nav-icon toggle-icon" />Twitter
            </a>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default Header;