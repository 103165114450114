import React from 'react';
import { useEffect } from 'react';

import { PiArrowCircleUpRightBold } from "react-icons/pi";
import { FaCodeBranch } from "react-icons/fa6";

import FootyBytes from '../../assets/images/footy_bytes.png';
import Barkers from '../../assets/images/barkers_lane.png';
import RickMorty from '../../assets/images/rick_morty.png';
import Bbq from '../../assets/images/bbqreview.png';

const Projects = () => {

  useEffect(() => {
    document.title = "Projects - Mixel"
  }, []);

  return (
    <section>
      <div className="block-side-margin">
        <div id="project-body" className="content-frame">
          <h1>My Proj<span class="logo-highlight highlight-light">e</span>cts & Work</h1>

          <div id="projects-group">
            <div className="project-column">
              <div>
                <p className="card-descriptor">Creative Website</p>
                <h3>Footy Byt<span class="logo-highlight highlight-light">e</span>s</h3>
              </div>
              <div className="project-main-img">
                <img src={FootyBytes} alt="Footy Bytes" />
              </div>
              <div className="project-text">
                <p>As a part of learning React and jsx, our class was challenged with an assignment to connect to an API.  Thus, Footy Bytes was born.  I built this Front-End React application to showcase my React skills and using promised based HTTP client Axios to fetch data from an API.</p>
                <p>Footy Bytes connects to the <a href="https://api.squiggle.com.au/" target="_blank" rel="noopener noreferrer">Squiggle AFL API</a> to pull out game results and dynamically display it on the site.  I decided to have some fun by dynamically displaying data into seasons and rounds.  Users can drill down from year to rounds, or even type a team name to see all the games they played that year.  Use of the Joi validation library was built into the project, to validate text input against valid responses (team names).</p>
                <p>Overall, the site design was build to give a video game feel and I enjoy revisiting it to continue improving on it.  Since developing the project, the AFL has added additional rounds to its fixture, creating an opportunity to improve the UI.  Once such approach I'm working on is dynamically generating round results for the drop down input by looping over the data.</p>

                <p className="card-tech-header">Built Using</p>
                <div className="icons">
                  <img src="https://img.icons8.com/?size=100&id=20909&format=png&color=000000" alt="HTML5" title="HTML5" />
                  <img src="https://img.icons8.com/?size=100&id=21278&format=png&color=000000" alt="CSS3" title="CSS3" />
                  <img src="https://img.icons8.com/?size=100&id=108784&format=png&color=000000" alt="JavaScript" title="JavaScript" />
                  <img src="https://img.icons8.com/?size=100&id=PndQWK6M1Hjo&format=png&color=000000" alt="Bootstrap" title="Bootstrap" />
                  <img src="https://img.icons8.com/?size=100&id=54087&format=png&color=000000" alt="Nodejs" title="Nodejs" />
                  <img src="https://img.icons8.com/?size=100&id=24895&format=png&color=000000" alt="NPM" title="NPM" />
                  <img src="https://img.icons8.com/?size=100&id=123603&format=png&color=000000" alt="React.js" title="React.js" />

                </div>
                <a
                  className="custom-btn btn-body btn-light"
                  href="https://footybytes.netlify.app/"
                  rel="noopener noreferrer"
                  target="_blank"
                >Visit Website&nbsp;<PiArrowCircleUpRightBold /></a>
                <a
                  className="custom-btn btn-body btn-bright"
                  href="https://github.com/mjegan19/footy-bytes"
                  rel="noopener noreferrer"
                  target="_blank"
                >View Repo&nbsp;<FaCodeBranch /></a>
              </div>
            </div>

            <div className="project-column">
              <div>
                <p className="card-descriptor">Creative Website</p>
                <h3>RickTastic MortyV<span class="logo-highlight highlight-light">e</span>rs<span class="logo-highlight highlight-light">e</span></h3>
              </div>
              <div className="project-main-img">
                <img src={RickMorty} alt="Ricktastic Mortyverse Website" />
              </div>
              <div className="project-text">
                <p>A Front-End JavaScript application build, making use of JavaScript libraries and demonstrating object oriented programming techniques.</p>
                <p><a href="https://rickandmortyapi.com/" target="_blank" rel="noopener noreferrer">The Rick and Morty API</a> was chosen as the subject topic of choice to meet the design specifications of connecting to, and pulling data from at least one API.  With the data obtained, a fun thought was to dynamically generate graphical 90s style football collector cards.</p>
                <p>To meet design specifications, Bootstrap components were incorporated into the design to capture user input and filtering of results.  Animation of the cards was introduced to bring the UI to life and make for an increased UX.</p>

                <p className="card-tech-header">Built Using</p>
                <div className="icons">
                  <img src="https://img.icons8.com/?size=100&id=20909&format=png&color=000000" alt="HTML5" title="HTML5" />
                  <img src="https://img.icons8.com/?size=100&id=21278&format=png&color=000000" alt="CSS3" title="CSS3" />
                  <img src="https://img.icons8.com/?size=100&id=108784&format=png&color=000000" alt="JavaScript" title="JavaScript" />
                  <img src="https://img.icons8.com/?size=100&id=PndQWK6M1Hjo&format=png&color=000000" alt="Bootstrap" title="Bootstrap" />
                </div>
                <a
                  className="custom-btn btn-body btn-light"
                  href="https://ricktastic-mortyverse.netlify.app/"
                  rel="noopener noreferrer"
                  target="_blank"
                >Visit Website&nbsp;<PiArrowCircleUpRightBold /></a>
                <a
                  className="custom-btn btn-body btn-bright"
                  href="https://github.com/mjegan19/GET_SCHWIFTY"
                  rel="noopener noreferrer"
                  target="_blank"
                >View Repo&nbsp;<FaCodeBranch /></a>
              </div>
            </div>

            <div className="project-column">
              <div>
                <p className="card-descriptor">Blog Website</p>
                <h3>Barb<span class="logo-highlight highlight-light">e</span>qu<span class="logo-highlight highlight-light">e</span> R<span class="logo-highlight highlight-light">e</span>staurant R<span class="logo-highlight highlight-light">e</span>vi<span class="logo-highlight highlight-light">e</span>w</h3>
              </div>
              <div className="project-main-img">
                <img src={Bbq} alt="BBQ Restuarant Reviews" />
              </div>
              <div className="project-text">
                <p>Tasked with creating a Content Management System using PHP & MySQL, the final site needed to be secured by a login system and allow a user to perform CRUD (create, read, update,) operations.</p>
                <p>I decided to create a restaurant blog site for reviews of American BBQ eateries.  Users are able to view review posts from data stored in a MySQL database.  Users can also create an account as well as log in and log out.  Once signed in to their account, users are able to create, edit, update or even delete reviews (posts).</p>
                <p>
                  Completing this project also taught important Security Requirements
                  <ul>
                    <li>Authentication & Authorisation</li>
                    <li>Password Encryption</li>
                    <li>Validation of Data</li>
                    <li>Error Messaging</li>
                  </ul>
                </p>
                <p className="card-tech-header">Built Using</p>
                <div className="icons">
                  <img src="https://img.icons8.com/?size=100&id=20909&format=png&color=000000" alt="HTML5" title="HTML5" />
                  <img src="https://img.icons8.com/?size=100&id=21278&format=png&color=000000" alt="CSS3" title="CSS3" />
                  <img src="https://img.icons8.com/?size=100&id=PndQWK6M1Hjo&format=png&color=000000" alt="Bootstrap" title="Bootstrap" />
                  <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/31/Webysther_20160423_-_Elephpant.svg/350px-Webysther_20160423_-_Elephpant.svg.png" alt="PHP" title="PHP" />
                  <img src="https://img.icons8.com/?size=100&id=UFXRpPFebwa2&format=png&color=000000" alt="MySQL" title="MySQL" />
                </div>
                <a
                  className="custom-btn btn-body btn-light disabled"
                  href="https://ricktastic-mortyverse.netlify.app/"
                  rel="noopener noreferrer"
                  target="_blank"
                >Visit Website&nbsp;<PiArrowCircleUpRightBold /></a>
                <a
                  className="custom-btn btn-body btn-bright"
                  href="https://github.com/mjegan19/bbqReview"
                  rel="noopener noreferrer"
                  target="_blank"
                >View Repo&nbsp;<FaCodeBranch /></a>
              </div>
            </div>

            <div className="project-column">
              <div>
                <p className="card-descriptor">Mock Website</p>
                <h3>Bark<span class="logo-highlight highlight-light">e</span>r's Lan<span class="logo-highlight highlight-light">e</span></h3>
              </div>
              <div className="project-main-img">
                <img src={Barkers} alt="Barker's Lane Website" />
              </div>
              <div className="project-text">
                <p>One of my first major projects since returning to Website Development.  The goal was to build a responsive static website using HTML5 & CSS3 for a topic of personal choice.  I landed on a fictional dog grooming business, and backfilled some of the content with 'placeholder text'.</p>
                <p>There was a requirement to create multiple pages / sections for the site, ensuring uniformity in layout, style & format across each.  Another important design feature was meeting accessibility standards, ensuring it met these standards via a validator.</p>
                <p className="card-tech-header">Built Using</p>
                <div className="icons">
                  <img src="https://img.icons8.com/?size=100&id=20909&format=png&color=000000" alt="HTML5" title="HTML5" />
                  <img src="https://img.icons8.com/?size=100&id=21278&format=png&color=000000" alt="CSS3" title="CSS3" />
                </div>
                <a
                  className="custom-btn btn-body btn-light"
                  href="https://barkerslane.netlify.app"
                  rel="noopener noreferrer"
                  target="_blank"
                >Visit Website&nbsp;<PiArrowCircleUpRightBold /></a>
                <a
                  className="custom-btn btn-body btn-bright"
                  href="https://github.com/mjegan19/barkers_lane"
                  rel="noopener noreferrer"
                  target="_blank"
                >View Repo&nbsp;<FaCodeBranch /></a>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  )
}

export default Projects