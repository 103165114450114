import React from 'react';
import { useEffect } from 'react';

import { BsPersonRaisedHand } from "react-icons/bs";
import { PiArrowCircleUpRightBold } from "react-icons/pi";

import Selfie from '../../assets/images/pixel_selfie.png';
import Grimleys from '../../assets/images/workstation/grimleys_desktop.png';
import Elementor from '../../assets/images/Elementor-Logo-Symbol-Pink.png';

const Home = () => {

  useEffect(() => {
    document.title = "Home - Mixel"
  }, []);

  return (
    <main>
      <div className="block-side-margin">

        {/* Hero Section */}
        <div id="hero-section" className="content-frame">
          <div id="left-hero-section">
            <div id="hero-text">
              G'day!<br />I'm Micha<span class="logo-highlight highlight-light">e</span>l & I d<span class="logo-highlight highlight-light">e</span>sign, build & t<span class="logo-highlight highlight-light">e</span>st w<span class="logo-highlight highlight-light">e</span>bsit<span class="logo-highlight highlight-light">e</span>s.
            </div>
            <div className="hero-tagline-text">
              <p>
                I'm a Melbourne based Web Developer experienced in both Front-End & Back-End technologies.         
              </p>
              <a
                className="custom-btn btn-light"
                href="about"
                rel="noreferrer"
              >Learn More&nbsp;<BsPersonRaisedHand /></a>
            </div>
          </div>
          <div id="right-hero-section">
            <img src={Selfie} alt="Placeholder Img - Site Graphic" />
          </div>
          <div id="hero-bg-gradient"></div>
        </div>
      </div>


      <section className="fp-color-block block-beta">
        <div id="block-gradient-overlay-light">
          <div className="block-side-margin">
            <div id="closer-look" className="content-frame">
              <h1>Tak<span class="logo-highlight highlight-bright">e</span> a clos<span class="logo-highlight highlight-bright">e</span>r look</h1>
              <p className="txt-sub-description">
                A recent IT graduate in the discipline of Web Development Technologies, I've attained a solid foundation in HTML, CSS & JavaScript, along with databases like SQL.  I've had experience developing PHP & CMS applications & during my diploma year focused on Web Frameworks & building out Full Stack Applications.
              </p>
              <div className="info-card-container">
                <div className="info-card card-light-one">
                  <div className="card-inner">
                    <div>
                      <p className="card-descriptor">Continuous Improvement</p>
                      <h2 className="highlight-dark">R<span class="logo-highlight highlight-light">e</span>s<span class="logo-highlight highlight-light">e</span>arch & Und<span class="logo-highlight highlight-light">e</span>rstanding</h2>
                      <p className="card-body">
                        Through recent academic achievements, I've honed my skills in both Front & Back End Development. By referencing documentation I go beyond the classroom in my efforts to improve & successfully deliver high quality websites.
                      </p>
                    </div>
                    {/* <div className="card-image">
                      <img src="https://placehold.co/150x250" alt="Placeholder Img - Site Graphic" />
                    </div> */}
                  </div>
                </div>

                <div className="info-card card-light-two card-inner">
                  <div className="card-inner">
                    <div>
                      <p className="card-descriptor">Detail Focused</p>
                      <h2 className="highlight-dark">D<span class="logo-highlight highlight-light">e</span>sign & D<span class="logo-highlight highlight-light">e</span>v<span class="logo-highlight highlight-light">e</span>lopm<span class="logo-highlight highlight-light">e</span>nt</h2>
                      <p className="card-body">
                        A keen eye for detail and the ability to troubleshoot and resolve issues efficiently. Whether it's debugging code for a website or conducting audits to ensure compliance, I approach tasks with diligence and precision.
                      </p>
                    </div>
                    {/* <div className="card-image">
                      <img src="https://placehold.co/150x250" alt="Placeholder Img - Site Graphic" />
                    </div> */}
                  </div>
                </div>

                <div className="info-card card-light-two card-inner">
                  <div className="card-inner">
                    <div>
                      <p className="card-descriptor">Teamwork</p>
                      <h2 className="highlight-dark">Working Tog<span class="logo-highlight highlight-light">e</span>th<span class="logo-highlight highlight-light">e</span>r</h2>
                      <p className="card-body">
                        Working with others helps to foster new ideas and processes of work.  In collaborating on Web Projects, I've found it strengthened project management and my knowledge of tools, provided opportunities for knowledge sharing and provided opportunities for code reviews.
                      </p>
                    </div>
                    {/* <div className="card-image">
                      <img src="https://placehold.co/150x250" alt="Placeholder Img - Site Graphic" />
                    </div> */}
                  </div>
                </div>

                <div className="info-card card-light-two card-inner">
                  <div className="card-inner">
                    <div>
                      <p className="card-descriptor">Leadership</p>
                      <h2 className="highlight-dark">Lighting Up Dir<span class="logo-highlight highlight-light">e</span>ction</h2>
                      <p className="card-body">
                        In both professional and academic environments I've built teams and steered direction. As a web developer leader I've help guide big picture focus, provided servant leadership demonstrated strong commmunication skills for both colleagues and clients, as well as be a model of resilience when challenges arise.
                      </p>
                    </div>
                    {/* <div className="card-image">
                      <img src="https://placehold.co/150x250" alt="Placeholder Img - Site Graphic" />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="fp-color-block block-white">
        <div className="block-side-margin">
          <div id="featured-project-header" className="content-frame">
            <h1>F<span class="logo-highlight highlight-light">e</span>atur<span class="logo-highlight highlight-light">e</span>d proj<span class="logo-highlight highlight-light">e</span>ct</h1>
          </div>
          <div id="featured-project-frame" className="content-frame">
            <div className="project-site">
              <img src={Grimleys} alt="" />
            </div>
            <div className="project-card">
              <p className="card-descriptor">Website Redevelopment</p>
              <h2 className="highlight-dark">Griml<span class="logo-highlight highlight-light">e</span>ys</h2>
              <a 
                href="https://www.grimleys.com.au" 
                className="card-link" 
                rel="noopener noreferrer"
                target="_blank">www.grimleys.com.au</a>
              <p className="description">"Grimleys are the No.1 source for fasteners and construction supplies"</p>
              <p>Completed as part of the <a href="https://djsir.vic.gov.au/priorities-and-initiatives/summertech-live" rel="noopener noreferrer" target="_blank">SummerTech Live Program</a>, this project involved overhauling Grimleys’ existing website.  Grimleys’ required a modern and professional looking branding site that could be built upon later to include a storefront.  WordPress was chosen for its flexibility to scale over time & Elementor chosen to build out the site components.</p>

              <p>Having recently undergone a refresh of their branding, it was important to ensure their site closely aligned with their new logo, colours and style guidelines.  It was also crucial to reflect the family component of business within the site itself.</p>

              <p className="card-tech-header">Built Using</p>
              <div className="icons">
                <img src={Elementor} alt="Elementor" title="Elementor" className="logo-adjust" />
                <img src="https://img.icons8.com/?size=100&id=13664&format=png&color=000000" alt="WordPress" title="WordPress" />
                <img src="https://img.icons8.com/?size=100&id=20909&format=png&color=000000" alt="HTML5" title="HTML5" />
                <img src="https://img.icons8.com/?size=100&id=21278&format=png&color=000000" alt="CSS3" title="CSS3" />
                <img src="https://img.icons8.com/?size=100&id=108784&format=png&color=000000" alt="JavaScript" title="JavaScript" />
              </div>
              <a
                className="custom-btn btn-body btn-light"
                href="https://www.grimleys.com.au"
                rel="noopener noreferrer"
                target="_blank"
              >Visit Website&nbsp;<PiArrowCircleUpRightBold /></a>
            </div>            
          </div>
        </div>        
      </section>


      {/* Technology Stack */}
      <section className="block-side-margin">
        <div id="tech-stack" className="content-frame">
          <h1>T<span class="logo-highlight highlight-light">e</span>ch knowl<span class="logo-highlight highlight-light">e</span>dg<span class="logo-highlight highlight-light">e</span> & <span class="logo-highlight highlight-light">e</span>xp<span class="logo-highlight highlight-light">e</span>ri<span class="logo-highlight highlight-light">e</span>nc<span class="logo-highlight highlight-light">e</span></h1>
          
          <div id="tech-icons">
            <img src="https://img.icons8.com/?size=100&id=zfHRZ6i1Wg0U&format=png&color=000000" alt="Figma" title="Figma" />
            <img src="https://img.icons8.com/?size=100&id=TUk7vxvtu6hX&format=png&color=000000" alt="ChatGPT" title="ChatGPT" />
            <img src="https://img.icons8.com/?size=100&id=87330&format=png&color=000000" alt="Google Firebase" title="Google Firebase" />
            <img src="https://img.icons8.com/?size=100&id=uY6QHPV8g5bd&format=png&color=000000" alt="Google Fonts" title="Google Fonts" />
            <img src="https://img.icons8.com/?size=100&id=13664&format=png&color=000000" alt="WordPress" title="WordPress" />
            <img src="https://img.icons8.com/?size=100&id=21278&format=png&color=000000" alt="CSS3" title="CSS3" />
            <img src="https://img.icons8.com/?size=100&id=20795&format=png&color=000000" alt="Codepen" title="Codepen" />
            <img src="https://img.icons8.com/?size=100&id=22813&format=png&color=000000" alt="Docker" title="Docker" />
            <img src="https://img.icons8.com/?size=100&id=63777&format=png&color=000000" alt="GitHub" title="GitHub" />
            <img src="https://img.icons8.com/?size=100&id=zdI5E8moxhs-&format=png&color=000000" alt="Graphql" title="Graphql" />
            <img src="https://img.icons8.com/?size=100&id=20909&format=png&color=000000" alt="HTML5" title="HTML5" />
            <img src="https://img.icons8.com/?size=100&id=108784&format=png&color=000000" alt="JavaScript" title="JavaScript" />
            <img src="https://img.icons8.com/?size=100&id=74402&format=png&color=000000" alt="MongoDB" title="MongoDB" />
            <img src="https://img.icons8.com/?size=100&id=UFXRpPFebwa2&format=png&color=000000" alt="MySQL" title="MySQL" />
            <img src="https://img.icons8.com/?size=100&id=24895&format=png&color=000000" alt="NPM" title="NPM" />
            <img src="https://img.icons8.com/?size=100&id=yUdJlcKanVbh&format=png&color=000000" alt="Next.js" title="Next.js" />
            <img src="https://img.icons8.com/?size=100&id=54087&format=png&color=000000" alt="Nodejs" title="Nodejs" />
            <img src="https://img.icons8.com/?size=100&id=123603&format=png&color=000000" alt="React.js" title="React.js" />
            <img src="https://img.icons8.com/?size=100&id=QBqFNfPPB2Kx&format=png&color=000000" alt="Sass" title="Sass" />
            <img src="https://img.icons8.com/?size=100&id=PndQWK6M1Hjo&format=png&color=000000" alt="Bootstrap" title="Bootstrap" />
            <img src="https://img.icons8.com/?size=100&id=74268&format=png&color=000000" alt="Filezilla" title="Filezilla" />
            <img src="https://img.icons8.com/?size=100&id=9OGIyU8hrxW5&format=png&color=000000" alt="Visual Studio Code" title="Visual Studio Code" />
            <img src="https://avatars.githubusercontent.com/u/20658825?s=200&v=4" alt="Styled Components" title="Styled Components" />
            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/31/Webysther_20160423_-_Elephpant.svg/350px-Webysther_20160423_-_Elephpant.svg.png" alt="PHP" title="PHP" />
          </div>
        </div>
      </section>
    </main>
  )
}

export default Home;