// Import Core React Modules
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ReactGA from "react-ga4";

// Import Custom StyleSheet
import './App.css';

// Import Components
import Header from './components/containers/Header';
import Home from './components/pages/Home';
import Layout from './components/layout/Layout';
import About from './components/pages/About';
import Projects from './components/pages/Projects';
import Contact from './components/pages/Contact';
import Footer from './components/containers/Footer';
import PageNotFound from './components/pages/PageNotFound';

const TRACKING_ID = "G-TS7JE4ZK42";

ReactGA.initialize(TRACKING_ID);
ReactGA.send({ hitType: "pageview", page: "/my-path", title: "Custom Title" });

function App() {
  return (
    <div className='App'>
      <Layout>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="projects" element={<Projects />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <Footer />
      </Layout>
    </div>
  );
  
}

export default App;
