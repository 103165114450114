import React from 'react';
import { useEffect } from 'react';

import { BiHomeHeart } from "react-icons/bi";
import Minifig from '../../assets/images/minifigure.png';

const PageNotFound = () => {

  useEffect(() => {
    document.title = "Page Not Found - Mixel"
  }, []);

  return (
    <section>
      <div className="block-side-margin">
        <div id="not-found" className="content-frame">
          <div className="not-found-grid">
            <div className="not-found-text">
              <h1>404 - Pag<span class="logo-highlight highlight-light">e</span> Not Found</h1>
              <div className="not-found-img-body">
                <img src={Minifig} alt="Radioactive Minifig" />
              </div>
              <p>Uh-oh! It appears you've wandered off.</p>
              <p>Let's get you back to where you need to be!</p>
              <a
                    className="custom-btn btn-light get-back"
                    href="/"
                    rel="noreferrer"
                  >Get Back Home&nbsp;<BiHomeHeart /></a>
            </div>
            <div className="not-found-img-aside">
              <img src={Minifig} alt="Radioactive Minifig" />
            </div>
          </div>          
        </div>
      </div>
    </section>
  )
}

export default PageNotFound