import React from 'react';
import { useEffect } from 'react';

import { AiOutlineMail } from "react-icons/ai";

const Contact = () => {

  useEffect(() => {
    document.title = "Contact - Mixel"
  }, []);

  return (
    <section>
      <div className="block-side-margin">
        <div id="contact-me" className="content-frame">
          <h1>Contact M<span class="logo-highlight highlight-light">e</span></h1>
          <p>Thanks for stopping by my site!</p>
          <p>For any permanent business employment opportunities, <br className="remove" />or to discuss freelance work on your next project, <br className="remove" />please contact me on any of the following below.</p>
          <p className="email">
            <span className="email-logo"><AiOutlineMail /></span>
            <span className="email-address"><a href="mailto:michaeljegan3@gmail.com">michaeljegan3@gmail.com</a></span>
          </p>
        </div>
      </div>
    </section>
  )
}

export default Contact