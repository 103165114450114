import React from 'react';
import { useEffect } from 'react';

import Computer from '../../assets/images/computer-code.jpg';

const About = () => {

  useEffect(() => {
    document.title = "About - Mixel"
  }, []);

  return (
    <section>
      <div className="block-side-margin">
        <div id="about-me" className="content-frame">
        <h1>All About M<span class="logo-highlight highlight-light">e</span></h1>

        <div className="about-grid">
          <div className="about-text">
            <h4>Hey there, I'm Michael & I make things for the World Wide Web.</h4>

            <div className="about-img about-img-body">
              <img src={Computer} alt="Laptop computer, with code in Visual Studio." />
            </div>
  
            <p>I've recently made the leap back into web development.&#160;&#160;I had previously obtained a degree from Deakin University, having undertaken a Bachelor of Computing (Multimedia Technology) course.&#160;&#160;In 2021, I made a decision to revist this career path, undertaking and successfully completing a Certificate IV in Information Technology (Web Development) at Holmesglen Institute.&#160;&#160;Now in 2022, I'm building on that foundation by completing a Diploma of Information Technology (Front & Back End Web Development).</p>
            <p>I've thoroughly enjoyed learning how web technologies have evolved since completing my original degree.&#160;&#160;Technologies like Bootstrap, React, etc weren't around back then and it has opened my eyes to just what is possible.&#160;&#160;It's certainly much different to creating a layout using the &#60;table&#62; element.</p>
            <p>In between my time studying, I built people skills and business acumen as a retail manager, leading 90 employees.&#160;&#160;It taught me a wealth of knowledge about communication, planning, structure and project management.  </p>
            <p>To request a copy of my CV, or to enquire about a web project you need built please <a href="mailto:michaeljegan3@gmail.com">email me.</a></p>

          </div>
          <div className="about-img about-img-aside">
            <img src={Computer} alt="Laptop computer, with code in Visual Studio." />
          </div>
        </div>




          {/* <div className="links">
            <a href={Resume} download><ImDownload /><span id="resume">&#160;&#160;Resume.pdf</span></a>
          </div>
          <h3>Resources</h3>
          <p className="resources about">
            This website makes use of icons from <a href="https://react-icons.github.io/react-icons" target="_blank" rel="noopener noreferrer">React-Icons</a>.<br />
            All fonts sourced from <a href="https://fonts.google.com/" target="_blank" rel="noopener noreferrer">Google Fonts</a>.<br />
            Additional site images sourced freely from <a href="https://unsplash.com/" target="_blank" rel="noopener noreferrer">Unsplash</a>.&#160;&#160;Credit to <a href="https://unsplash.com/@mullyadii" target="_blank" rel="noopener noreferrer">Mulyadi</a> & <a href="https://unsplash.com/@nublson" target="_blank" rel="noopener noreferrer">Nubelson Fernandes</a>.
          </p> */}
        </div>

      </div>
    </section>
  )
}

export default About