import React from 'react';
import { SiGithub } from 'react-icons/si';
import { FaTwitter } from "react-icons/fa";

import { PiGhostBold, PiDesktopTowerBold, PiMegaphoneBold } from "react-icons/pi";
import { FaHouseUser } from "react-icons/fa";
import { HiSquaresPlus } from "react-icons/hi2";

import IndigenousFlag from '../../assets/images/flag_indig_icon.png';
import TorresIsldFlag from '../../assets/images/flag_torres_icon.png';
import PrideFlag from '../../assets/images/flag_pride_icon.png';

const Footer = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  return (
    <footer>
      <div className="fp-color-block block-beta-darker">
        <div className="block-side-margin">
          <div className="content-frame">
            <div className="footer-frame">
              <div className="ftr-col ftr-col-left">
                <a className="logo-text" href="/">&lt;mix<span className="logo-highlight highlight-light">e</span>l&gt;</a>
                <p className="location">Made in Melbourne, AU.</p>
              </div>

              <div className="ftr-col ftr-col-middle">
                <ul>
                  <li>
                    <span>
                      <HiSquaresPlus className="nav-icon flip-vertical" />Pages
                    </span>
                  </li>
                  <li>
                    <a href="/">
                      <FaHouseUser className="nav-icon" />Home
                    </a>
                  </li>
                  <li>
                    <a href="about">
                      <PiGhostBold className="nav-icon" />About
                    </a>
                  </li>
                  <li>
                    <a href="projects">
                      <PiDesktopTowerBold className="nav-icon" />Projects
                    </a>
                  </li>
                  <li>
                    <a href="contact">
                      <PiMegaphoneBold className="nav-icon flip-horizontal" />Contact
                    </a>
                  </li>
                </ul>
              </div>
              
              <div className="ftr-col ftr-col-right">
              <ul>
                  <li>
                    <a
                      className=""
                      href="https://github.com/mjegan19"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <SiGithub />
                    </a>
                  </li>
                  <li>
                    <a
                      className=""
                      href="https://twitter.com/mixelweb"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaTwitter />
                    </a>
                  </li>
                </ul>
                <p>
                  &#169; Copyright {currentYear} mixelweb.dev
                  <br /><span>All rights reserved.</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="sub-footer-container block-side-margin">
        <div className="first-nation-flags">
          <img src={IndigenousFlag} alt="Indigenous Flag" />
          <img src={TorresIsldFlag} alt="Torres Straight Islander Flag" />
        </div>
        <div className="first-nation-statement">
          <h4>Acknowledgement of Country</h4>
          <p>This website and all associated work is created on the lands of the Wurundjeri people of the Kulin nation.<br />
          In the spirit of reconciliation, we acknowledge all First Nations people as the Traditional Owners of Country throughout Australia.<br />
          We pay respect to their cultures and Elders past and present.</p>
        </div>
        <div className="pride-flags">
          <img src={PrideFlag} alt="Pride Flag" />
        </div>
        <div className="pride-statement">
          <h4>Diversity, Equality & Inclusion</h4>
          <p>We respect and welcome people of all backgrounds, genders, sexualities, abilities and cultures.</p>
        </div>
      </div>

      <div id="footer-gutter" className="fp-color-block block-beta-darker"></div>

    </footer>
  )
}

export default Footer